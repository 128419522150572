/*

    1.jb-Basic            

    2.jb-Helper           

    3.jb-Navbar           

    4.jb-Home             

    5.jb-My Story         

    6.jb-About Us         

    7.jb-Services         

    8.jb-Testimonials     

    9.jb-Our Team         

    10.jb-Premises        

	11.jb-Appointment  

    12.jb-FAQ's           

    13.jb-Footer          

    14.jb-Responsive      

                        */

/*~~~

        1.jb-Basic

                    ~~~*/

html,
body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#AboutUs {
    padding-top: 110px;
}

#Services{
    padding-top: 110px;
}

#Testimonials{
    padding-top: 110px;
}

#FAQ{
    padding-top: 110px;
}

#Appointment {
    scroll-margin-top: 50px;
}
  

body {
    font-family: "Source Sans Pro", sans-serif;

    font-weight: 400;

    background-color: #fff;

    overflow-x: hidden;

    animation: pageAnimation ease 1s;

    animation-iteration-count: 1;

    animation-fill-mode: forwards;

    position: relative;
}

@keyframes pageAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.jb-sub-title {
    font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Source Sans Pro", sans-serif;

    font-weight: 700;
}

p {
    font-size: 15px;

    line-height: 1.6;
}

h4 {
    font-size: 24px;

    font-weight: 600;

    color: #161e39;

    position: relative;

    line-height: 1.3em;
}

a,
a:hover,
a:focus,
button,
button:focus {
    text-decoration: none !important;

    outline: none !important;

    box-shadow: none !important;
}

::selection {
    background: rgba(166, 175, 189, 0.3);
}

::-moz-selection {
    background: rgba(166, 175, 189, 0.3);
}

.jb-back_top {
    border: 2px solid;

    background: #fff;

    text-align: center;

    position: fixed;

    bottom: 20px;

    right: 20px;

    width: 35px;

    height: 35px;

    display: none;

    transition: all 0.5s;

    border-radius: 10px !important;

    animation: BounceAnimation 4s infinite;

    -webkit-animation: BounceAnimation 4s infinite;

    -moz-animation: BounceAnimation 4s infinite;

    -o-animation: BounceAnimation 4s infinite;

    z-index: 99;
}

.jb-back_top i {
    font-size: 30px;

    line-height: 30px;

    display: block;
}

.mb-30 {
    margin-bottom: 30px;
}

@keyframes BounceAnimation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

@-webkit-keyframes BounceAnimation {
    0%,
    15%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-10px);
    }
}

@-moz-keyframes BounceAnimation {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-20px);
    }

    60% {
        -moz-transform: translateY(-10px);
    }
}

/*~~~
                                        
                                                2.jb-Helper
                                        
                                                            ~~~*/

.jb-color {
    color: #c98895 !important;
}

.jb-b-color {
    border-color: #c98895 !important;
}

.jb-bg-color {
    background-color: #c98895 !important;
}

.jb-bg-gradient {
    background: rgb(164, 31, 181);

    background: linear-gradient(
        30deg,
        rgba(164, 31, 181, 0.846673703661152) 0%,
        rgba(253, 150, 5, 0.8550770650056898) 100%
    );

    background: rgb(164, 31, 181);

    background: radial-gradient(
        circle,
        rgba(164, 31, 181, 0.846673703661152) 0%,
        rgba(253, 150, 5, 0.8550770650056898) 100%
    );
}

.font-white {
    color: #fff;
}

.section {
    padding-top: 60px;

    padding-bottom: 60px;

    position: relative;

    background-color: #fff;
}

.jb-section-title h2 {
    position: relative;
}

.jb-section-subtitle {
    font-size: 16px;

    max-width: 550px;

    padding-top: 0;

    line-height: 25px;
}

.z-index-9 {
    position: relative;

    z-index: 9;
}

.z-index {
    z-index: 2;
}

.btn {
    padding: 12px 28px;
}

.btn {
    font-size: 14px;

    text-align: center;

    padding: 0;

    text-transform: uppercase;

    font-weight: 600;

    height: 46px;

    line-height: 46px;
}

.btn-sm {
    padding: 8px 15px;
}

.jb-btn-rounded {
    -webkit-border-radius: 30px !important;

    -moz-border-radius: 30px !important;

    border-radius: 30px !important;
}

.jb-btn-white {
    background-color: #fff;

    color: #000 !important;

    font-size: 14px;

    font-weight: 500;

    letter-spacing: 1px;

    min-width: 140px;

    border-radius: 40px;

    -moz-border-radius: 40px;

    -webkit-border-radius: 40px;

    transition: all 0.5s;

    text-transform: capitalize;

    -webkit-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);

    -moz-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);

    box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.jb-btn-white:hover,
.jb-btn-white:focus,
.jb-btn-white:active,
.jb-btn-white.active,
.jb-btn-white.focus,
.jb-btn-white:active,
.jb-btn-white:focus,
.jb-btn-white:hover,
.open > .dropdown-toggle.jb-btn-white {
    color: #c98895 !important;
}

.logo-square-border {
    border: 3px white solid;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.logo-square-border-2 {
    border: 3px white solid;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}

.logo-circle-border {
    border: 2px white solid;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

/*~~~
                                        
                                                3.jb-Navbar
                                        
                                                            ~~~*/

.jb-custom-nav {
    padding: 18px 0px;

    /* background-color: transparent; */

    background-color: #fff;

    width: 100%;

    -webkit-border-radius: 0px;

    -moz-border-radius: 0px;

    border-radius: 0px;

    margin-bottom: 0px;

    /* z-index: 999; */

    border-bottom: 1px solid #ffffff1a;
}

.jb-custom-nav .container-fluid {
    width: 90%;
}

.jb-custom-nav.sticky-header {
    background-color: #fff;

    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}

.jb-custom-nav .navbar-nav li a {
    position: relative;

    font-size: 16px;

    font-weight: 600;

    padding: 6px 16px !important;

    margin: 0 3px;
}

.jb-custom-nav .navbar-nav li a::before,
.jb-custom-nav .navbar-nav li a::after {
    position: absolute;

    content: "";

    width: 12px;

    height: 2px;

    background-color: #c98895;

    left: 50%;

    bottom: 0px;

    opacity: 0;

    -webkit-transition: all 0.3s ease-out 0s;

    -moz-transition: all 0.3s ease-out 0s;

    -o-transition: all 0.3s ease-out 0s;

    transition: all 0.3s ease-out 0s;
}

.jb-custom-nav .navbar-nav li a::after {
    left: auto;

    right: 50%;
}

.jb-custom-nav .navbar-nav li.active a::before,
.jb-custom-nav .navbar-nav li.active a::after,
.jb-custom-nav .navbar-nav li:hover a::before,
.jb-custom-nav .navbar-nav li:hover a::after {
    opacity: 1;
}

.jb-custom-nav.sticky-header .navbar-nav li a {
    color: rgba(0, 0, 0, 0.6) !important;
}

.jb-custom-nav.sticky-header .navbar-nav li a::before,
.jb-custom-nav.sticky-header .navbar-nav li a::after {
    background-color: #c98895;
}

.navbar-toggle {
    color: #ffffff;

    font-size: 24px;

    margin-top: 5px;

    margin-bottom: 0px;
}

.navbar-toggler {
    font-size: 28px;

    margin-top: 2px;

    margin-bottom: 0px;
}

.jb-custom-nav .navbar-nav li.active a,
.jb-custom-nav .navbar-nav li:hover a,
.jb-custom-nav .navbar-nav li:active a {
    color: #c98895;
}

.jb-custom-nav.sticky-header .navbar-nav li.active a,
.jb-custom-nav.sticky-header .navbar-nav li:hover a,
.jb-custom-nav.sticky-header .navbar-nav li:active a {
    color: #c98895 !important;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: transparent;

    border-color: #c98895;
}

.jb-brand-logo .jb-logo-light {
    display: inline-block;
}

.jb-brand-logo .jb-logo-dark {
    display: none;
}

.sticky-header .jb-brand-logo .jb-logo-light {
    display: none;
}

.sticky-header .jb-brand-logo .jb-logo-dark {
    display: inline-block;
}

.jb-custom-nav .navbar-brand.jb-brand-logo img {
    height: 50px;
}

.width-reducer {
    width: 0;
}

.jb-contact_btn .btn-sm {
    background-color: #fff;

    border: 2px solid #fff;

    font-size: 14px;

    text-transform: capitalize;

    font-weight: 600;

    min-width: 130px;

    height: 40px;

    border-radius: 40px;

    display: flex;

    justify-content: center;

    transition: none;

    align-items: center;

    letter-spacing: 1px;

    -webkit-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15) !important;

    -moz-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15) !important;

    box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15) !important;
}

.sticky-header .jb-contact_btn .btn-sm {
    background-color: #c98895 !important;

    border: 2px solid #c98895 !important;

    color: #fff !important;
}

.jb-contact_btn .btn-sm:hover {
    color: #fff !important;

    background-color: #000 !important;

    border: 2px solid #000 !important;

    transition: 1s;
}

.jb-contact_btn .jb-social-icon {
    display: block;
    background: #fff;
    font-size: 25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transition: all 0.5s;
}

/*~~~
                                        
                                                4.jb-Home
                                        
                                                            ~~~*/

.jb-home-bg,
.jb-home-section {
    position: relative;
}

.jb-home-bg::before {
    content: "";

    top: 0;

    left: 0;

    right: 0;

    bottom: 0;

    position: absolute;

    opacity: 0.75;

    z-index: 1;
}

.jb-home-bg .heading {
    color: #fff;

    font-size: 60px;

    line-height: 60px;

    margin-bottom: 15px;
}

.jb-home-bg .jb-para-sec {
    font-size: 20px;

    color: rgba(255, 255, 255, 0.85);

    margin-bottom: 20px;

    margin-top: 20px;

    /* 
                                            added margin-top and */
}

.jb-para-name {
    font-size: 40px;
    line-height: 40px;
}

.home-img-square {
    padding: 20px 25px 25px 25px;
}

.home-img-circle {
    padding: 50px 60px 60px 55px;
}
.home-img-circle-2 {
    padding: 50px;
}

.jb-home-slider {
    position: relative;

    z-index: 9;
}

.jb-home-bg.jb-waves-bg {
    margin: 0 0 0;

    padding: 6rem 0 0 0;
    /*changed 8rem to 6rem*/
}

.hero-bg {
    /* background-image: url(); */

    opacity: 1;

    top: 0;

    left: 0;

    height: 100%;

    width: 100%;
}

@keyframes owl-jb-content-fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);

        opacity: 1;
    }
}

@-webkit-keyframes owl-jb-content-fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);

        opacity: 1;
    }
}

@keyframes owl-jb-img-fadeInRight {
    from {
        transform: translateX(40px);
    }

    to {
        transform: translateX(0);

        opacity: 1;
    }
}

@-webkit-keyframes owl-jb-img-fadeInRight {
    from {
        transform: translateX(40px);
    }

    to {
        transform: translateX(0);

        opacity: 1;
    }
}

.owl-item.active .jb-content-fadeInUp {
    animation-duration: 1s;

    animation-fill-mode: both;

    -webkit-animation-duration: 1s;

    -webkit-animation-fill-mode: both;

    opacity: 0;

    animation-name: owl-jb-content-fadeInUp;

    -webkit-animation-name: owl-jb-content-fadeInUp;
}

.owl-item.active .jb-img-fadeInRight {
    animation-duration: 1s;

    animation-fill-mode: both;

    -webkit-animation-duration: 1s;

    -webkit-animation-fill-mode: both;

    opacity: 0;

    animation-name: owl-jb-img-fadeInRight;

    -webkit-animation-name: owl-jb-img-fadeInRight;
}

.jb-home-slider .owl-dots,
.jb-testimonial-slider .owl-dots {
    position: absolute;

    left: 50%;

    bottom: 0;

    transform: translateX(-50%);
    text-align: center;
}

.jb-home-slider .owl-dot,
.jb-testimonial-slider .owl-dot {
    background: rgba(255, 255, 255, 0.4) !important;

    height: 10px;

    width: 10px;

    -webkit-border-radius: 10px;

    -moz-border-radius: 10px;

    margin: 0 5px;

    border-radius: 10px;
}

.jb-home-slider .owl-dot.active,
.jb-testimonial-slider .owl-dot.active {
    background: #fff !important;
}

.jb-hero-waves {
    height: 120px;

    position: relative;
}

.jb-hero-waves .wave {
    position: absolute;

    left: 0;

    right: 0;

    top: 0;

    z-index: 1;
}

.jb-hero-waves .wave + .wave {
    z-index: 2;
}

/*~~~
                                        
                                    certificates
                                        
                                                            ~~~*/

.cer-box {
    margin: 5px;
    border: 2px solid;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
    -webkit-box-shadow: 0 0 10px 0 rgba(72, 73, 121, 0.3) !important;
    -moz-box-shadow: 0 0 10px 0 rgba(72, 73, 121, 0.3) !important;
    box-shadow: 0 0 10px 0 rgba(72, 73, 121, 0.3) !important;
}

.cer-size {
    height: 320px;
}

/* .cer-card {
                        transform: perspective(100px) translateZ(0px);
                        transition: transform 300ms linear;
                      } */

.cer-card:hover {
    scale: 1.05;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    /* transform: perspective(100px) translateZ(5px); */
}

/* .cer-card-text {
                        transform: perspective(100px) translateZ(0px);
                        transition: transform 300ms linear;
                      }
                       */
.cer-card:hover .cer-card-text {
    scale: 1.05;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    /* transform: translate3d(0px, 5px, 5px); */
}

/* .cer-card-img {
                        transform: perspective(100px) translateZ(0px);
                        transition: transform 300ms linear;
                      } */

.cer-card:hover .cer-card-img {
    scale: 1.05;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    /* transform: perspective(100px) translateZ(5px); */
}

/*~~~
                                        
                                                5.jb-My Story
                                        
                                                            ~~~*/

.story-margin {
    margin: 50px 0px;
    padding: 100px 0px;
}

/* my story image gallery */
.story-img-gallery-main {
    padding: 30px;
    scale: 0.75;
}
.story-img-gallery {
    border: solid 3px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 10px 10px 30px #c98895;
    -webkit-box-shadow: 10px 10px 30px #c98895;
    -moz-box-shadow: 10px 10px 30px #c98895;
    -ms-box-shadow: 10px 10px 30px #c98895;
    -o-box-shadow: 10px 10px 30px #c98895;
}
.gallery {
    padding: 10px 15px;
}

.gallery-img {
    margin: 10px 0px;
    transform: perspective(100px) translateZ(0px) rotate(0deg);
    transition: transform 300ms linear;
    -webkit-transition: transform 300ms linear;
    -moz-transition: transform 300ms linear;
    -ms-transition: transform 300ms linear;
    -o-transition: transform 300ms linear;
}

.gallery-img > img {
    border-radius: 10px;
}

.gallery-img:hover {
    transform: perspective(100px) translateZ(10px) rotate(5deg);
    -webkit-transform: perspective(100px) translateZ(10px) rotate(5deg);
    -moz-transform: perspective(100px) translateZ(10px) rotate(5deg);
    -ms-transform: perspective(100px) translateZ(10px) rotate(5deg);
    -o-transform: perspective(100px) translateZ(10px) rotate(5deg);
}

/*======================
                                        
                                                6.jb-About Us
                                        
                                        ========================*/

.jb-aboutus {
    background-color: #f7f9fb;
}

.jb-aboutus .jb-icon-boxes h4 {
    font-size: 18px;

    margin-bottom: 15px;
}

.jb-aboutus .jb-aboutus-icon-box {
    margin-top: 30px;
}

.jb-aboutus .jb-aboutus-icon-box .icon {
    border: 2px solid;

    border-radius: 50px;

    float: left;

    display: flex;

    align-items: center;

    justify-content: center;

    min-width: 64px;

    min-height: 64px;

    transition: 0.5s;

    background: #fff;
}

.jb-aboutus .jb-aboutus-icon-box .icon i {
    font-size: 32px;
}

.jb-aboutus .jb-aboutus-icon-box:hover .icon {
    background: #c98895;

    border-color: #c98895;
}

.jb-aboutus .jb-aboutus-icon-box:hover .icon i {
    color: #fff;
}

.jb-aboutus .jb-aboutus-icon-box .title {
    font-weight: 700;

    margin-bottom: 10px;
}

.jb-aboutus .jb-aboutus-icon-box .jb-description {
    line-height: 24px;

    font-size: 14px;

    text-align: left;

    margin-bottom: 0;
}

.jb-about-icon {
    padding: 5px 5px 0px 0px;
}

/* .about-card-text{
                        transform: perspective(100px) translateZ(0px);
                        transition: transform 300ms linear;
                    }
                    .about-card-text:hover{
                        transform: perspective(100px) translateZ(5px);
                    } */

.about-heading:hover,
.about-card-text:hover {
    scale: 1.05;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.jb-about-logo {
    position: relative;
    bottom: 60px;
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);

        opacity: 1;
    }

    100% {
        transform: scale(1, 1);

        opacity: 0;
    }
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);

        opacity: 1;
    }

    100% {
        transform: scale(1, 1);

        opacity: 0;
    }
}

/*~~~
                                        
                                                7.jb-Services
                                        
                                                                    ~~~*/

.jb-dept-box {
    margin-bottom: 30px;

    border-bottom: 2px solid;

    border-radius: 10px;

    min-height: 580px;

    position: relative;

    overflow: hidden;
}

.jb-dept-box .jb-dept-details {
    background-color: #fbf7f8;

    padding: 0 15px 20px;

    bottom: -65px;

    text-align: center;

    position: absolute;

    transition: all 0.3s ease;

    -moz-transition: all 0.3s ease;

    -webkit-transition: all 0.3s ease;

    -ms-transition: all 0.3s ease;

    -o-transition: all 0.3s ease;

    width: 100%;
}

.jb-dept-details .jb-round-style {
    background: #fbf7f8 none repeat scroll 0 0;

    border-radius: 50%;

    height: 70px;

    margin: 0 auto;

    position: absolute;

    left: 0;

    top: -35px;

    right: 0;

    width: 70px;
}

.jb-dept-box .jb-dept-details h4 {
    font-size: 18px;

    font-weight: 700;
}

.jb-dept-box:hover .jb-dept-details {
    bottom: 0;
}

.jb-dept-box .jb-dept-details .icon {
    display: inline-block;

    font-size: 28px;

    position: relative;

    top: -27px;
}

.jb-dept-box .jb-dept-details .jb-details {
    font-size: 16px;
}

.jb-dept-box .jb-dept-details a.jb-thm-btn {
    margin-top: 15px;

    padding: 6px 15px;

    color: #fff;

    border: 2px solid transparent;

    border-radius: 30px;

    display: inline-block;

    font-size: 14px;

    text-transform: uppercase;

    transition: all 0.3s ease;
}

.jb-dept-box .jb-dept-details a.jb-thm-btn:hover {
    background-color: #fff !important;

    color: #c98895;

    border-color: #c98895;
}

.jb-dept-box .jb-dept-details a.jb-thm-btn.inverse {
    background-color: #fff !important;

    color: #c98895;

    border-color: #c98895;
}

.jb-dept-box .jb-dept-details a.jb-thm-btn.inverse:hover {
    background-color: #c98895;

    color: #fff;

    border-color: #c98895;
}

.jb-services-list {
    list-style-type: none;
}

.contact-btn {
    list-style: none;
}

/*====================
                                services-sub-section
                                =======================*/
.before-479 {
    display: block;
}
.after-479 {
    display: none;
}
.ser-sub-main-h {
    margin-bottom: 75px;
}

.list-main {
    margin-bottom: 20px;
    padding: 5px;
}

.list-style {
    list-style-type: square;
    font-size: 18px;
}
.list-style > li {
    padding-left: 10px;
}

.list-style:hover {
    cursor: pointer;
    color: #c98895;
    transition: 0.5s;
}

/*==========================
                                        
                                                8.jb-Testimonials
                                        
                                        ============================*/

.jb-testimonial-slider .item .jb-user-txt {
    display: flex;

    align-items: center;

    padding: 1rem 0 2rem 0;

    padding-left: 0.25rem;
}

.jb-testimonial-slider .item .jb-user-txt::after {
    display: block;

    clear: both;

    content: "";
}

.jb-testimonial-slider .item .jb-user-txt .jb-user-pics {
    margin-right: 1rem;

    width: 60px;

    height: 60px;

    float: left;

    border-radius: 100%;

    overflow: hidden;
}

.jb-testimonial-slider .item .jb-user-txt .jb-user-pics img {
    width: 100%;

    max-width: 100%;

    height: auto;
}

.jb-testimonial-slider .item .jb-user-txt .jb-user-info {
    float: left;
}

.jb-testimonial-slider .item .jb-user-txt .jb-user-info .heading {
    margin-bottom: 0.25rem;

    margin-top: 0.25rem;

    line-height: 1;
}

.jb-testimonial-slider .item .jb-user-txt .jb-user-info .jb-sub-heading {
    margin-bottom: 0;

    font-size: 14px;

    font-weight: 600;

    line-height: normal;
}

.jb-testimonial-slider .item .jb-testimonial-card {
    padding: 1.5rem;

    position: relative;

    background: #fff;

    text-align: center;

    border-radius: 0.5rem;

    overflow: visible;
}

.jb-testimonial-slider .item .jb-testimonial-card p {
    margin-bottom: 0;

    color: #414c5a;

    font-weight: 600;
}

.jb-testimonial-slider .item .jb-testimonial-card::before {
    content: "";

    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzgwODlGRjt9Cjwvc3R5bGU+CjxnIGlkPSJRdW90ZW1hcmtzLWxlZnQiPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQwNSwyMDkuOGMtMS0xMS4xLTAuMi00MS41LDI4LjgtODMuNmMyLjItMy4yLDEuOC03LjUtMC45LTEwLjJjLTExLjgtMTEuOC0xOS4yLTE5LjMtMjQuMy0yNC41CgkJYy02LjgtNi45LTkuOC0xMC0xNC40LTE0LjFjLTMtMi43LTcuNi0yLjgtMTAuNi0wLjFjLTUwLjYsNDQtMTA2LjgsMTM1LTk4LjcsMjQ2LjVjNC44LDY1LjUsNTIuNSwxMTMsMTEzLjUsMTEzCgkJYzYyLjYsMCwxMTMuNS01MC45LDExMy41LTExMy41QzUxMiwyNjIuOCw0NjQuNiwyMTMuMiw0MDUsMjA5Ljh6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIwLjksMjA5LjhjLTEtMTEuMS0wLjMtNDEuNCwyOC44LTgzLjZjMi4yLTMuMiwxLjgtNy41LTAuOS0xMC4yYy0xMS44LTExLjgtMTkuMS0xOS4zLTI0LjMtMjQuNQoJCWMtNi44LTYuOS05LjktMTAuMS0xNC40LTE0LjJjLTMtMi43LTcuNi0yLjctMTAuNi0wLjFjLTUwLjYsNDQtMTA2LjgsMTM1LTk4LjcsMjQ2LjV2MGM0LjgsNjUuNCw1Mi41LDExMywxMTMuNSwxMTMKCQljNjIuNiwwLDExMy41LTUwLjksMTEzLjUtMTEzLjVDMjI3LjksMjYyLjgsMTgwLjUsMjEzLjIsMTIwLjksMjA5Ljh6Ii8+CjwvZz4KPC9zdmc+Cg==");

    background-size: cover;

    display: block;

    width: 40px;

    height: 40px;

    position: absolute;

    top: 0.75rem;

    left: 1rem;

    opacity: 0.2;
}

.tes-para {
    text-align: left;
}

/*~~~
                                        
                                                11.jb-Appointment
                                        
                                                                    ~~~*/

.jb-appointment .jb-contant-number {
    /* background: url("assets/contact-us-image.jpg") center center; */

    background-size: cover;

    background-position: center center;

    position: relative;

    height: 100%;
}

.jb-appointment .jb-contant-number h3 {
    font-size: 35px;

    font-weight: bold;

    margin: 0;

    padding: 25px 20px;

    position: absolute;

    bottom: 100px;

    right: 0;

    background: rgba(0, 0, 0, 0.69);

    color: #ffffff;

    line-height: 0;
}

.jb-appointment .jb-appointment-form .title {
    margin-bottom: 30px;

    font-size: 32px;

    color: #ffffff;

    font-family: "Poppins", sans-serif;
}

.jb-appointment .jb-appointment-form {
    max-width: 90%;

    padding: 60px 0 60px 0;

    margin: auto;
}

.jb-book-now-btn {
    padding: 0 15px;

    background-color: #ffffff;

    color: #fd9605;

    font-weight: bold;

    text-transform: uppercase;

    border-radius: 6px;

    height: 40px;

    line-height: 40px;
}

.jb-book-now-btn:hover {
    background-color: #000;

    color: #fff;

    box-shadow: 0px 0px 10px #fd9605 !important;

    transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
}

.form-control {
    font-size: 14px;
}

.form-control:hover {
    outline: #fd9605 3px solid !important;
    box-shadow: 0px 0px 15px #fd9605;
}
.form-control:focus {
    outline: solid 3px #fd9605 !important;
}

.emailResponse {
    color: white;
}

/* validation */

.form-group span {
    font-size: 12px;
    color: maroon;
}

#submit-error {
    color: black;
}

.form-group span i {
    color: greenyellow;
}

.alertMsg {
    position: relative;
    left: -15px;
}
.form-group .alertMsg > i {
    color: maroon;
}

/*~~~
                                        
                                                12.jb-FAQ's
                                        
                                                                ~~~*/

.jb-faq-panel {
    margin-bottom: 2rem;
}

.jb-faq-header {
    border: none;
}

.jb-question-box {
    margin-bottom: 1rem;

    border: 1px solid #eee;

    border-radius: 4px;

    backface-visibility: hidden;

    -webkit-backface-visibility: hidden;

    background: #fff;
}

.jb-question-box .btn-link {
    margin-top: 0;

    margin-bottom: 0;

    padding: 1rem 1.5rem;

    font-size: 18px;

    font-weight: 700;

    position: relative;

    background: #f8f8f8;

    text-align: left;

    cursor: pointer;

    transition: color 0.25s ease-out;

    width: 100%;

    border: none;
}

.jb-question-box .jb-faq-body {
    padding: 0.5rem 1rem;
}

.jb-question-box .jb-faq-body p {
    margin-bottom: 5px;
}

.jb-question-box .btn-link.collapsed {
    color: #161e39 !important;
}

.jb-question-box .btn-link {
    display: flex;

    align-items: center;

    justify-content: space-between;
}

.jb-question-box.active h4::after {
    color: #c98895 !important;

    top: 14px;

    -webkit-transform: rotate(-180deg);

    transform: rotate(-180deg);
}

.jb-question-box.active .btn-link .caret-icon {
    transform: rotateZ(-180deg);
}

.caret-icon {
    display: flex;

    justify-content: center;

    align-items: center;

    width: 25px;

    font-size: 24px;

    line-height: 20px;
}

.faq-arrow {
    font-size: 14px;
    margin: 5px;
}

/*~~~
                                        
                                                13.jb-Footer
                                        
                                                            ~~~*/

.footer {
    position: relative;

    padding-top: 75px;

    overflow: hidden;
}

.footer::after {
    content: "";

    display: block;

    position: absolute;

    height: 100%;

    top: -5px;

    right: -4px;

    left: -1px;

    z-index: 1;
}

.jb-footer-bottom,
.jb-footer-top {
    position: relative;

    z-index: 2;
}

.footer .jb-foot-logo img {
    height: 78px;
    padding: 10px;
}

.footer .jb-footer-menu li {
    margin-bottom: 10px;

    -webkit-transition: all 0.5s;

    -moz-transition: all 0.5s;

    transition: all 0.5s;
}

.footer .jb-footer-menu li:hover {
    margin-left: 10px;
}

.footer h5 {
    color: #fff;
}

.footer p {
    color: #fff;
}

.footer .jb-footer-menu li a,
.footer .jb-footer-menu li i {
    display: inline-block;

    font-size: 16px;

    color: #fff !important;

    transition: all 0.5s;
}

.footer .jb-footer-menu li a:hover,
.footer .jb-footer-menu li:hover i {
    color: #fff !important;
}

.footer .jb-footer-social .jb-social-icon {
    display: block;

    background: #fff;

    /* color: #c98895; */

    font-size: 20px;

    width: 40px;

    height: 40px;

    line-height: 40px;

    text-align: center;

    -webkit-border-radius: 50%;

    -moz-border-radius: 50%;

    border-radius: 50%;

    transition: all 0.5s;
}

.footer .jb-footer-social .jb-social-icon:hover {
    color: #fff !important;

    background-color: #000;
}

.jb-footer-desc {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.jb-contact-menu-list i {
    font-size: 20px;

    margin-right: 10px;

    margin-top: 5px;
}

.jb-quick-contact i {
    display: none;
}

.jb-contact-menu-list li {
    display: flex;

    align-items: flex-start;

    line-height: normal;
}

.footer .jb-footer-newsletter {
    font-size: 15px;
}

.jb-foot-logo-main {
    padding-bottom: 10px;
    margin-left: 15px;
}

.jb-foot-logo > img {
    background-color: #fff;
    -webkit-animation: footerlogo 2.5s ease infinite;
    -moz-animation: footerlogo 2.5s ease infinite;
    animation: footerlogo 2.5s ease infinite;
}

.footer-heart {
    color: red;
    font-size: 13px;
}

@keyframes footerlogo {
    0% {
        box-shadow: 0px 0px 1px white;
    }
    50% {
        box-shadow: 0px 0px 15px white;
    }
    100% {
        box-shadow: 0px 0px 1px white;
    }
}

@-webkit-keyframes footerlogo {
    0% {
        -webkit-box-shadow: 0px 0px 1px white;
    }
    50% {
        -webkit-box-shadow: 0px 0px 15px white;
    }
    100% {
        -webkit-box-shadow: 0px 0px 1px white;
    }
}

@-moz-keyframes footerlogo {
    0% {
        -moz-box-shadow: 0px 0px 1px white;
    }
    50% {
        -moz-box-shadow: 0px 0px 15px white;
    }
    100% {
        -moz-box-shadow: 0px 0px 1px white;
    }
}

/*~~~
                                        
                                                14.jb-Responsive
                                        
                                                                    ~~~*/

@media (max-width: 1480px) {
    .footer {
        padding-top: 200px;
    }
}

@media (max-width: 1366px) {
    .jb-home-bg.jb-waves-bg {
        margin: 0 0 0;

        padding: 5rem 0 0 0;
        /* changed the padding from 7rem to 5rem */
    }

    .jb-home-slider .owl-dots {
        position: absolute;

        left: 50%;

        bottom: 50px;

        transform: translateX(-50%);
    }
}

@media (max-width: 1199px) {
    .container {
        max-width: 99%;
    }

    .jb-about-scale {
        scale: 0.93;
    }
}

@media (max-width: 1100px) {
    .footer {
        padding-top: 150px;
    }
    .story-img-gallery-main {
        bottom: 0px;
    }
    .story-margin {
        padding: 0px;
    }
}

@media (max-width: 991px) {
    .jb-home-slider .owl-dots {
        bottom: 10px;
    }

    .jb-home-social {
        text-align: center;
    }

    .jb-home-bg.jb-waves-bg {
        margin: 0 0 0;

        padding: 4rem 0 0 0;
    }

    .jb-contact_btn .btn-sm {
        background: #c98895 !important;

        border: 2px solid #c98895 !important;

        color: #fff !important;
    }

    .jb-home-slider .owl-item img {
        display: table;

        margin: auto;

        max-width: 50%;
    }

    .jb-home-slider .owl-item .actions {
        display: table;

        margin: 0 auto 20px;
    }

    .jb-custom-nav .jb-brand-logo .jb-logo-dark {
        display: inline-block;
    }

    .jb-custom-nav .jb-brand-logo .jb-logo-light {
        display: none;
    }

    .jb-contact_btn {
        display: flex;
    }

    .jb-custom-nav {
        background-color: #fff !important;

        margin-top: 0px;

        padding: 10px 0px !important;
    }

    .jb-custom-nav .navbar-nav li a {
        color: #000;

        text-align: center;

        margin: 0 0px;

        padding: 6px 0;
    }

    .jb-custom-nav .navbar-nav li.active a,
    .jb-custom-nav .navbar-nav li:hover a {
        background-color: #fff !important;

        color: #c98895 !important;

        border-radius: 0;
    }

    .jb-custom-nav > .container {
        width: 90%;
    }

    .jb-custom-nav .jb-brand-logo {
        padding: 0px;
    }

    .jb-custom-nav .navbar-brand.jb-brand-logo img {
        height: 36px;
    }

    .navbar-collapse {
        background: #f8f8f8 !important;

        border-top: 2px solid #eee !important;

        position: fixed;

        top: 61px;

        left: 0;

        width: 100%;

        padding: 0;
    }

    .navbar-nav {
        margin-top: 0px;
    }

    .section {
        padding-top: 30px;

        padding-bottom: 30px;
    }

    .jb-section-title h2,
    .jb-section-subtitle {
        text-align: center;
    }

    .jb-custom-nav {
        padding: 12px 0;
    }

    .container {
        max-width: 98%;
    }

    .m-text-center {
        text-align: center;
    }

    p {
        text-align: center;
    }

    .jb-faq-body p {
        text-align: left;
    }

    .jb-section-subtitle {
        max-width: 500px;

        font-size: 16px;

        padding-top: 0;

        display: table;

        margin: auto;
    }

    .jb-foot-logo {
        display: table;

        margin: auto;
    }

    .jb-ftr-about {
        text-align: center !important;
    }

    .jb-footer-social {
        text-align: center;
    }

    .m-box-0 {
        margin-bottom: 0;
    }

    .jb-our-team .team-box .jb-title-box h4 {
        text-align: center;
    }

    .btn {
        height: 35px !important;

        line-height: 35px;
    }

    .ser-sub-main-h {
        margin-bottom: 50px;
    }
    .list-main {
        margin-bottom: 15px;
    }

    .list-style {
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .story-image {
        margin-right: 75px;
    }
    .home-img-square {
        padding: 15px 20px 20px 20px;
    }
    .home-img-circle {
        padding: 15px 25px 25px 15px;
    }

    .jb-number-main > h3 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 918px) {
    .after-918 {
        display: block;
    }
    .before-918 {
        display: none;
    }
    .story-content {
        margin-top: 0px;
        padding: 15px 15px 0px 15px;
    }

    .story-image {
        margin-right: 0px;
    }
    .story-content > h4 {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .footer {
        padding-top: 100px;
    }

    .m-d-flex {
        display: flex;
    }

    .section {
        padding-top: 40px;

        padding-bottom: 40px;
    }

    .jb-section-subtitle {
        line-height: 1.6;
    }

    .m-al-c {
        display: flex;

        justify-content: center;
    }

    .jb-appointment .jb-contant-number {
        height: 500px;
    }

    .jb-number-main {
        text-align: center !important;
    }
    .jb-btn-main {
        text-align: center !important;
    }
}

@media only screen and (max-width: 767px) {
    .jb-home-slider .owl-item img {
        max-width: 70%;

        display: table;

        margin: auto;
    }

    .jb-home-slider .owl-dots {
        bottom: 0;
    }

    .jb-home-bg .heading {
        font-size: 40px;

        line-height: 40px;

        text-align: center;
    }

    .home-img-square {
        padding: 10px 15px 15px 15px;
    }
    .home-img-circle {
        padding: 25px 35px 35px 30px;
    }
    .img-margin {
        margin-top: 20px;
    }

    .container {
        max-width: 98%;
    }

    .jb-section-title h2 {
        font-size: 26px;
    }

    .footer .jb-footer-menu li:hover {
        margin-left: 0;
    }

    .jb-our-team .team-box .jb-title-box h4 {
        text-align: center;
    }

    .jb-learn-more {
        margin: auto;

        display: table;
    }

    .jb-sub-title {
        text-align: center;
    }

    .jb-custom-nav .navbar-brand.jb-brand-logo img {
        height: 34px;
    }

    .jb-appointment .jb-appointment-form {
        padding: 50px 0 50px 0px;
    }

    .jb-appointment .jb-appointment-form .title {
        text-align: center;
    }

    .cer-para {
        font-size: 18px;
    }

    .cer-size {
        height: 400px;
    }

    .ser-sub-main-h {
        margin-bottom: 30px;
    }
    .list-main {
        margin-bottom: 10px;
    }
    .jb-number-main {
        text-align: center !important;
        margin-top: 20px;
    }
    .jb-number-main > h3 {
        font-size: 24px;
    }

    .jb-btn-main {
        text-align: center !important;
    }
    .list-style > li {
        padding-left: 5px;
    }
    .story-margin {
        margin: 0px;
    }
}

@media (max-width: 599px) {
    .jb-testimonial-slider .jb-user-txt {
        justify-content: center;

        padding: 1rem 0 4rem !important;
    }

    .jb-testimonial-slider .item .jb-testimonial-card::after {
        display: none;
    }
}

@media (max-width: 575px) {
    .margin-top-sb-30 {
        margin-top: 30px;
    }

    .jb-home-bg .heading {
        font-size: 40px;

        line-height: 40px;
    }

    .jb-home-slider .owl-item img {
        max-width: 80%;

        display: table;

        margin: auto;
    }

    .jb-home-slider .owl-dots {
        bottom: -10px;
    }

    .jb-footer-menu {
        display: none;

        margin-top: 10px !important;
    }

    .jb-footer-menu-static {
        display: block !important;
    }

    .jb-footer-title {
        position: relative;

        cursor: pointer;
    }

    .jb-footer-title i {
        position: absolute;

        right: 0;

        margin-top: 1px;

        font-size: 18px;
    }

    .jb-quick-contact {
        position: relative;

        cursor: pointer;
    }

    .jb-quick-contact i {
        position: absolute;

        right: 0;

        margin-top: 1px;

        font-size: 18px;
        display: inline;
    }

    .jb-footer-title.active i {
        transform: rotateZ(-180deg);
    }
}

@media only screen and (max-width: 479px) {
    .footer {
        padding-top: 50px;
    }

    .jb-brand-logo.navbar-brand {
        margin-right: 5px !important;
    }

    .jb-custom-nav .navbar-brand.jb-brand-logo img {
        height: 32px;
    }

    .story-image > img {
        width: 200px;
    }

    .home-img-square {
        padding: 5px 10px 10px 10px;
    }

    .home-img-circle {
        padding: 20px 30px 30px 25px;
    }
    .list-main {
        margin-bottom: 5px;
        padding: 2px;
    }

    .before-479 {
        display: none;
    }
    .after-479 {
        display: block;
    }

    .cer-img {
        height: 150px !important;
    }
    .cer-container {
        margin: 10px 0px;
    }
    .list-style {
        font-size: 16px;
    }
    .jb-contact_btn .btn-sm {
        min-width: 105px !important;
        font-size: 13px;
    }
    .jb-number-main > h3 {
        font-size: 33px;
        letter-spacing: 1.5px;
    }
    .jb-aboutus .jb-aboutus-icon-box .icon {
        min-width: 48px;
        min-height: 48px;
    }
    .jb-aboutus .jb-aboutus-icon-box .icon i {
        font-size: 26px;
    }
    .jb-about-space {
        margin-right: 2.5rem;
        margin-left: 0.5rem !important;
    }
    .jb-testimonial-slider .owl-dot {
        margin: 0px 12px;
    }
    .jb-aboutus .jb-sub-title {
        font-size: 30px;
    }
    .jb-about-logo .img-fluid {
        max-width: 40%;
    }
}

@media only screen and (max-width: 376px) {
    .jb-number-main > h3 {
        font-size: 30px;
        letter-spacing: 0px;
    }
    .jb-testimonial-slider .owl-dot {
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 321px) {
    .cer-img {
        height: 125px !important;
    }
    .jb-contact_btn .btn-sm {
        min-width: 95px !important;
        font-size: 12px;
    }
    .list-style {
        font-size: 15px;
        margin: 1px;
        padding: 4px;
    }
    .jb-number-main > h3 {
        font-size: 26px;
    }
    .jb-about-space {
        margin-right: 2rem;
        margin-left: 0.2rem !important;
    }
    .jb-testimonial-slider .owl-dot {
        margin: 0px 7px;
    }
}

.slideInLeftJb {
    animation: slideInLeftJb linear;
}

@keyframes slideInLeftJb {
    0% {
        transform: translateX(-5%);
    }
    100% {
        transform: translateX(0%);
    }
}

.slideInRightJb {
    animation: slideInRightJb linear;
}

@keyframes slideInRightJb {
    0% {
        transform: translateX(5%);
    }
    100% {
        transform: translateX(0%);
    }
}

.slideInUpJb {
    animation: slideInUpJb linear;
}

@keyframes slideInUpJb {
    0% {
        transform: translateY(5%);
    }
    100% {
        transform: translateY(0%);
    }
}

.jb-cert-round {
    border-radius: 80%;
    border: solid #c98895 2px;
}

.jb-cert-border {
    border: #0097b2 solid 4px;
}

.jb-cert-button {
    background: #c98895 !important;
    color: white !important;
    padding: 0 20px;
}

.jb-cert-button:hover,
.jb-cert-button:focus,
.jb-cert-button:active,
.jb-btn-white.active,
.jb-cert-button.focus,
.jb-cert-button:active,
.jb-cert-button:focus,
.jb-cert-button:hover,
.open > .dropdown-toggle.jb-cert-button {
    color: whitesmoke !important;
}

@media (max-width: 768px) {
    .life-vision-board .col-md-6:first-child {
        order: 2;
    }

    .life-vision-board .col-md-6:last-child {
        order: 1;
    }
}


.container.jb-about-scale {
    padding: 0;
}

.mce-error-response {
    display: none;
}

.mce-success-response {
    display: none;
}

.jb-appointment-element {
    position: absolute;
    left: -5000px;
}

.icofont-check-circled {
    color: #adff2f;
}

#Certifications {
    padding-top: 110px;
}

.success-response {
    color: white; 
}

.error-response {
    color: red;
}

@media (max-width: 768px) {
    #AboutUs {
        margin-left: 10px;
        margin-right: 10px;
    }
}
